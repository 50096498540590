import React, { Component } from 'react';
import { withTranslation, Translation, } from 'react-i18next';

import {BrowserView,MobileView,TabletView, isMobileOnly, isTablet} from 'react-device-detect';

import { View, ActivityIndicator} from "react-native-web";

import HeaderPC from '../../header/HeaderPC';
import HeaderMobile from '../../header/HeaderMobile';
import HeaderTablet from '../../header/HeaderTablet';

const viewportWidth = window.innerWidth;
const viewportHeight = window.innerHeight;

export default function LoadingAppPage() {




    return (

	         <div>


                  {!isMobileOnly && !isTablet &&
                    <BrowserView>
                      <View style={{width:viewportWidth, height: viewportHeight, justifyContent: 'center', alignSelf: 'center', backgroundColor: '#231e2a'}}>

                        <HeaderPC />

                        <div style={{textAlign: 'center', marginBottom: '30px'}}><img src={'https://d3van47cb9u9tj.cloudfront.net/jeunemais/jeunemais_logo_web_vert_transp_800.png'} alt="Jeune Mais Logo" style={{width:'380px'}}/></div>



                        <ActivityIndicator  animating={true} color="#0079ff" />
                      </View>
                   </BrowserView>
                 }



                 {isMobileOnly &&
           			  <MobileView>
                    <View style={{width:viewportWidth, height: viewportHeight-160, justifyContent: 'center', alignSelf: 'center', backgroundColor: 'eef0f1'}}>


                      <ActivityIndicator  animating={true} color="#0079ff" />
                    </View>
           			 </MobileView>
           			}

           			{isTablet &&
           			  <TabletView>
                      <View style={{width:viewportWidth, height: viewportHeight, justifyContent: 'center', alignSelf: 'center', backgroundColor: '#231e2a'}}>

                          <HeaderTablet />

                        <div style={{textAlign: 'center', marginBottom: '30px'}}><img src={'https://d3van47cb9u9tj.cloudfront.net/jeunemais/jeunemais_logo_web_vert_transp_800.png'} alt="Jeune Mais Logo" style={{width:'380px'}}/></div>



                        <ActivityIndicator  animating={true} color="#0079ff" />
                      </View>
           			  </TabletView>
           			}








	        </div>

    )

}
