import React, { Component, useState  } from 'react';
import {
  useNavigate,
  useLocation,
  Link
} from 'react-router-dom';
import { withTranslation, Translation, } from 'react-i18next';
import i18n from "../../i18n";

import { View, Text, Image, ActivityIndicator} from "react-native-web";

import HamburgerMenu from 'react-hamburger-menu';

const viewportWidth = window.innerWidth;
const viewportHeight = window.innerHeight;

export default function HeaderTablet(props) {
  const currentYear = new Date().getFullYear();
  const [open, setOpen] = useState(false);
   const navigate = useNavigate();

   const location = useLocation();

   let pathname = location.pathname;


   const handleClick = () => {
       let bool = !open;
       setOpen(bool);

       props.handleMenuClick(bool);
   }


    return (

      <div className="main_header" style={{backgroundColor: '#231e2a', borderBottom: 'none'}}>
        <div className="main_header_inside">
          <div className="main_header_inside_left">
              <a href="/"><img src={'https://d3van47cb9u9tj.cloudfront.net/jeunemais/jeune_mais_logo_web_transp.png'} alt="Jeune Mais Logo" style={{width: '133px', height: '40px'}}/></a>
          </div>

          <div className="main_header_inside_left_2" style={{display: 'none', width: '70%'}}>
               <a href="/dplay" className="nonactiveHeaderLink">DPlay</a>
              <a href="/about/dshop" className="nonactiveHeaderLink">DShop</a>
              <a href="/about/dchat" className="nonactiveHeaderLink">DChat</a>
              <a href="https://www.yinitj.com/" target="_blank" className="nonactiveHeaderLink">Youth&nbsp;Initiative&nbsp;Jeunesse</a>
          </div>

          <div className="main_header_inside_right_1" style={{display: 'none',lineHeight: '45px', fontSize: '18px'}}>
             {pathname.indexOf('/products') < 0 && pathname.indexOf('/solutions') < 0 && pathname.indexOf('/contact') < 0 && <span><a href='/' style={{color: '#0079ff'}}>Accueil</a>&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;<a href='/products' style={{color: '#0079ff'}}>Produits</a>&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;<a href='/solutions' style={{color: '#0079ff'}}>Solutions</a>&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;<a href='/contact' style={{color: '#0079ff'}}>Contact</a>&nbsp;&nbsp;&nbsp;</span>}
             {pathname.indexOf('/products') > -1 && <span><a href='/' style={{color: '#0079ff'}}>Accueil</a>&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;<a href='/products' style={{color: '#fff', backgroundColor: '#0079ff', padding: '5px', borderRadius: '4px'}}>Produits</a>&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;<a href='/solutions' style={{color: '#0079ff'}}>Solutions</a>&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;<a href='/contact' style={{color: '#0079ff'}}>Contact</a>&nbsp;&nbsp;&nbsp;</span>}
             {pathname.indexOf('/solutions') > -1 && <span><a href='/' style={{color: '#0079ff'}}>Accueil</a>&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;<a href='/products' style={{color: '#0079ff'}}>Produits</a>&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;<a href='/solutions' style={{color: '#fff', backgroundColor: '#0079ff', padding: '5px', borderRadius: '4px'}}>Solutions</a>&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;<a href='/contact' style={{color: '#0079ff'}}>Contact</a>&nbsp;&nbsp;&nbsp;</span>}
             {pathname.indexOf('/contact') > -1 && <span><a href='/' style={{color: '#0079ff'}}>Accueil</a>&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;<a href='/products' style={{color: '#0079ff'}}>Produits</a>&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;<a href='/solutions' style={{color: '#0079ff'}}>Solutions</a>&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;<a href='/contact' style={{color: '#fff', backgroundColor: '#0079ff', padding: '5px', borderRadius: '4px'}}>Contact</a>&nbsp;&nbsp;&nbsp;</span>}
          </div>



          {(props.hdrIsRel === undefined || (props.hdrIsRel !== undefined && props.hdrIsRel !== true)) &&
            <div className="main_header_inside_right_1" style={{marginTop: '17px', marginRight: '10px'}}>
                   <HamburgerMenu
                        isOpen={open}
                        menuClicked={handleClick}
                        width={35}
                        height={18}
                        strokeWidth={1}
                        rotate={0}
                        color='white'
                        borderRadius={0}
                        animationDuration={0.5}
                       />
            </div>
          }

          {(props.page === undefined || props.page !== "JoinUs") &&
          <div className="main_header_inside_right_1" style={{display: 'none', marginTop: '5px', fontSize: '18px'}}>
           <a href="/rejoindre-le-mouvement">
              <div style={{backgroundColor: '#0179ff', borderRadius: '6px', width: '220px', height: '40px', lineHeight: '40px', borderRadius: '4px', color: '#fff', fontSize: '16px', textAlign: 'center'}}>
                 {'Rejoignez le mouvement'}
              </div>
           </a>
          </div>
        }

        <div className="main_header_inside_right_1" style={{display: 'none', lineHeight: '40px', marginRight: '10px'}}>
            <a href="/contact" style={{fontSize: '18px', color: '#3a81dd'}}>Nous contacter</a>
        </div>

          <div style={{clear: 'both'}}/>

        </div>
      </div>

    )

}
